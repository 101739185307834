.country-selector-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 50px;
}

.country-path {
  opacity: .5;

   .contour-path {
    fill: #687bb2;
  }
}

.map-background {
  background-color: #ffffff;
}

.country-selector-group {
  display: block;
  position: relative;
  width: 54.2vw;
  margin: 0 auto;
}

.selector-selected-country {
  padding: 10px 40px 10px 20px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  width: 54.2vw;
  margin: 0 auto;
  font-size: 3.75vw;
  color: #000000;
  text-align: left;
  cursor: pointer;

  margin-bottom: 30px;
}

.selector-selected-country > .country-selector-list-item {
  padding-left: 0;
}

.country-selector {
  display: block;
  padding: 0;
  padding-bottom: 1.67vw;
  margin-top: -1.67vw;
  border: 1px solid #bdbdbd;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: absolute;
  background-color: #ffffff;
  width: 100%;
  z-index: 10;
}

.country-selector-container {
  padding-top: 0;
}

 .country-badges {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .country-badge-container:last-of-type {
    img {
      border: .5px solid gray;
    }
  }
}

.country-selector-images {
  display: block;
  padding: 20px;
  margin-bottom: 40px;
}

.country-badge-container {
  margin: 10px;
  cursor: pointer;
  opacity: 0.85;
  border-radius: 20px !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: none !important;
  max-width: 50%;
  align-self: stretch;
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .select-country-card-top {
    align-items: center;
    color: black;
    margin-bottom: 15px;
    text-align: center;
    img {
      margin-bottom: 15px;
      margin-right: 0;
    }
    .h5 {
      margin: 0;
      font-weight: bold;
    }
  }
  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  @media (max-width: 768px) {
    width: 100% !important;
    min-width: 100% !important;
    height: 100%  !important;
    align-self: center;
  }
}

.custom-title h5 {
  text-align: center;
  font-size:30px;
  font-weight:300;
  color:#525252;
  text-transform: uppercase;

  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}
.custom-title h5:before, .custom-title h5:after {
  content: " ";
  display: block;
  border-bottom: 1px solid #1ca0db;
  border-top: 1px solid #1ca0db;
  height: 5px;
  background-color:#f8f8f8;
}

.country-badge-image {
  margin-right: 15px;
  transition: width 0.3s;
}

.country-badge-container:hover {
  opacity: 1;
}

.country-badge-container:hover > .country-badge-image {
  width: 15vw;
}

.country-selector-list-item {
  list-style: none;
  height: 50px;
  padding: 10px 20px;
  width: 100%;
  display: flex; 
  align-items: center;
  color: #000000;
  cursor: pointer;
  font-size: 24px
}

.country-selector-field {
  position: relative;
}

.country-selector-list-item:hover {
  background-color: #2d9cdb25;
  transition: background-color 0.3s;
}

.country-selector-list-item-image {
  height: 90%;
  margin-right: 15px;
}

.select-country-title {
  font-size: 48px;
  text-align: center;
  padding-top: 3.5vw;
  margin-bottom: 40px;
}

.nuka-slide-container {
  width: 100%;
}

.country-path:hover, .country-path.active{
  opacity: 1;

  .contour-path {
    fill: #00237d;
  }
}

.select-country-title {
  padding: 0 20px;
}

.loop-countries {
  width: 100%;
  height: auto;
  margin-top: 40px;
  z-index: 1;
}

.map-container {
  height: 36.46vw;
  width: 100%;
  margin-top: 20px;
  display: flex;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}

.map-background {
  width: 100%;
}

.shop-card-text {
  font-size: 1.25vw;
  line-height: 1.3em;
  font-weight: bold;
  color: #1ba0db;
  padding: 0;
}

svg {
  position: absolute !important;
  z-index: 1;
  width: 100%;
  height: auto;
}

.contour-path {
  cursor: pointer;
}

.not-available {
  width: 100%;
  min-height: 90px;
  padding: 5px 27px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  font-size: 1.6vw;
  color: #ffffff;
  background-color: #2d9cdb;
}

.not-available > a {
  z-index: 2;
}

.not-available > a > .btn-light {
  color: #2d9cdb;
  height: 36px;
  padding: 5px 45px;
}

.not-available-text {
  margin: 15px 0;
  font-size: 27px;
  font-weight: bold;
}

.activation-card-list {
  display: flex;
  margin: 50px 0 0;
  width: 100%;
}

.shop-page-subtitle {
  margin-top: 90px;
  padding: 0 7.64vw;
  text-align: center;
  font-size: 2.7vw;
  font-weight: bold;
}

.our-brands-text {
  margin: 10px 0 55px;
  padding: 0 20px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.learn-more-button {
  text-decoration: none !important;
  text-align: center;
  color: #ffffff;
  background-color: #1ca0db !important;
  font-size: 21px;

  &:hover {
    background-color: #198fc5 !important;;
    text-decoration: none !important;
  }
}

.shop-card-link {
  text-decoration: none;
  width: 100%;

  margin: 20px 0;
}

.shop-card-link:hover {
  text-decoration: none;
}

.selected-country-container {
  padding: 0;
  margin-bottom: 90px;
}

.country-pin {
  position: absolute;
  z-index: 11;
  width: 4%;
  opacity: 0.5;
  cursor: pointer;
}

.selector-selected-country {
  opacity: 1;
  transition: opacity 0.6s;
  position: relative;
}

.selector-caret {
  position: absolute;
  right: -1vw;
  transition: transform 0.5s;
}

.retailer-selector-caret {
  transition: transform .8s;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #1ca0db;
}

.selector-caret.rotated, .retailer-selector-caret.rotated {
  transform: rotate(180deg);
}

.selector-selected-country:hover > .country-selector-list-item {
  background-color: #ffffff;
}


.slider-card {
  box-shadow: 0px 8px 20px #00000029;
  border-radius: 4px;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  max-width: 32%;
  min-width: 30%;
  margin-left: 20px;
  margin-right: 20px;
  height: 31vw;

  &:hover {
    box-shadow: 0px 8px 20px #afafaf;
  }
}

.card-image-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.77vw;
  min-height: 100px;
  margin: 2vw auto 30px;
}

.shop-card-image {
  width: 80%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.shop-details-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: none;
  text-align: center;
}

.shop-details-card > .card-body {
  display: flex;
  flex-direction: column;
  padding: 2.08vw;
  position: relative;
}

.ribbon {
  width: 220px;
  height: 220px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #296993;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 200px;
  padding: 6px 0;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font-weight: 700;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-align: center;
  line-height: normal;
  font-size: 14px;
}

.ribbon.instore-flag span {
  background-color: #004b76;
}
.ribbon.online-flag span {
  background-color: #1ca0db;
  width: 110px;
  left: 68px;
}
.ribbon-top-right.online-flag::after {
  bottom: 46px;
  right: 0;
}
.ribbon.coming-soon-flag span {
  background-color: #527895;
}

/* top right*/
.ribbon-top-right {
  top: 0px;
  right: -10px;
}

.ribbon-top-right.online-flag {
  top: -29px;
  width: 150px;
  height: 150px;
}

.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::after {
  bottom: 85px;
  right: 0;
}
.ribbon-top-right span {
  left: 62px;
  top: 40px;
  transform: rotate(45deg);
}

.ribbon::before {
  display: none !important;
}

.selected-country {
  opacity: 1;
  transition: opacity 0.6s;
}

.stay-tuned-button {
  margin-left: 28px;
}

.double-activation-slider {
  width: calc(50vw - 20px) !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.double-activation-slider > .slider-frame {
  width: calc(50vw - 20px);
}

@media (max-width: 1300px) {
  .double-activation-slider {
    width: calc(75vw - 20px) !important;
  }

  .double-activation-slider > .slider-frame {
    width: calc(75vw - 20px);
  }
}

@media (max-width: 1279px) {
  .map-container {
    height: 550px;
  }
}

@media (max-width: 1080px) {
  .slider-card {
    min-height: 400px;
  }

  .shop-card-text {
    font-size: 14px;
  }

  .double-activation-slider {
    width: calc(90vw - 20px) !important;
  }

  .double-activation-slider > .slider-frame {
    width: calc(90vw - 20px);
  }

  .shop-details-card > .card-body {
    padding: 3.4vw;
  }
}

@media (max-width: 991px) {
  .map-contaianer {
    height: 50vw;
  }

  .slider-card {
    min-width: 40%;
    max-width: 45%;
  }
}

@media (max-width: 720px) {
  .not-available-text {
    font-size: 5vw;
  }

  .country-selector-group {
    display: block;
    position: relative;
    width: 54.2vw;
    margin: 0 auto;
  }

  .selector-selected-country {
    padding: 1.67vw 3.47vw;
    border: 1px solid #bdbdbd;
    border-radius: 4px;
    width: 54.2vw;
    margin: 0 auto;
    font-size: 3.75vw;
    color: #000000;
    text-align: left;
    cursor: pointer;

   margin-bottom: 30px;
  }

  .selector-selected-country > .country-selector-list-item {
    padding-left: 0;
  }

  .country-selector {
    display: block;
    padding: 0;
    padding-bottom: 1.67vw;
    margin-top: -1.67vw;
    border: 1px solid #bdbdbd;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    z-index: 10;
  }

  .country-selector-container {
    padding-top: 0;
  }

  .activation-card-list {
    width: 92vw;
    margin-left: 28px;
    margin-right: 28px;
  }

  .shop-page-subtitle {
    margin-top: 115px;
    font-size: 6.95vw;
  }

  .slider-card {
    min-height: 730px;

    min-width: 60%;
    max-width: 65%;
  }

  .slider-slide {
    margin: auto 10px auto 20px !important;
  }

  .not-available {
    font-size: 3.5vw;
    padding: 12px 27px;
  }

  .stay-tuned-button {
    border-radius: 5.55vw;
    font-size: 4.3vw;
    line-height: 4.3vw;
    height: 11.11vw;
    width: auto;
    margin: 0;
    margin-bottom: 15px;
    padding: 3.4vw 20px;
    width: 52vw;
  }

  .card-image-container {
    min-height: 90px;
  }

  .shop-card-text {
    font-size: 3.89vw;
  }

  .our-brands-text {
    font-size: 3.9vw;
  }

  .learn-more-button {
    border-radius: 26px !important;
    margin: 0 30px 35px;
    font-size: 4.3vw;
    line-height: 4.3vw;
  }

  .map-container {
    display: none;
  }

  .country-badges {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }

  .country-selector-images {
    display: block;
    padding: 40px;
  }

  .country-badge-container {
    height: 16vw;
    width: 16vw;
    margin: 2vw;
    cursor: pointer;
    opacity: 0.85;
  }

  .country-badge-image {
    width: 14vw;
    transition: width 0.3s;
  }

  .country-badge-container:hover {
    opacity: 1;
  }

  .country-badge-container:hover > .country-badge-image {
    width: 15vw;
  }

  .country-selector-list-item {
    list-style: none;
    height: 50px;
    padding: 2.1vw 3.47vw;
    width: 100%;
    display: flex; 
    align-items: center;
    color: #000000;
    cursor: pointer;
    font-size: 3.75vw;
  }

  .country-selector-field {
    position: relative;
  }

  .country-selector-list-item:hover {
    background-color: #2d9cdb25;
    transition: background-color 0.3s;
  }

  .country-selector-list-item-image {
    height: 90%;
    margin-right: 15px;
  }

  .select-country-title {
    font-size: 6.95vw;
    padding-top: 3.3vw;
    margin-bottom: 5.55vw;
  }

  .not-available {
    font-size: 5vw;
  }

  .shop-details-card > .card-body {
    padding: 6.25vw;
  }
  
}

@media (max-width: 600px) {
  .slider-card {
    min-height: 575px;
  }
}

@media (max-width: 550px) {
  .slider-card {
    min-height: 500px;
  }
}

@media (max-width: 440px) {
  .country-selector-group {
    width: 74.2vw;
  }

  .selector-selected-country {
    width: 74.2vw;
  }

  .country-selector-list-item {
    font-size: 14px;
  }

  .multi-activation-slider > .slider-frame > .slider-list > .slider-slide > .slider-card {
    width: 95% !important;
    margin: 0!important;
    max-width: unset !important;
  }

}

@media (max-width: 400px) {
  .slider-card {
    min-height: 390px;
  }
}

@media (min-width: 1920px) {
  .not-available-text {
    font-size: 1.4vw;
  }

  .not-available {
    padding: 12px 27px;
  }

  .stay-tuned-button {
    margin: 15px 0 15px 1.5vw;
  }

  .shop-page-subtitle {
    margin-top: 130px;
    font-size: 2.7vw;
  }

  .activation-card-list {
    margin: 90px 0 0;
    justify-content: center;
  }

  .shop-details-card > .card-body {
    padding: 60px;
  }

  .card-image-container {
    height: 8vw;
    margin-top: 0;
  }

  .our-brands-text {
    font-size: 1.05vw;
  }

  .activation-card-list  .slider-card {
    max-width: 22.92vw !important;
  }

  .learn-more-button {
    font-size: 1.1vw;
    bottom: 0;
  }

  .country-selector-container {
    padding-top: 3.6vw;
  }

  
  .shop-card-image {
    width: 100%;
  }
}