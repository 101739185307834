@import "~bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700&display=swap");

body {
  color: #1ba0db;

  font-family: 'Nunito', sans-serif;
  font-size: 1.125rem;
  margin: 0;
  padding: 0;

  overflow-x: hidden;
}

h1 {
  font-size: 3rem;
}

.ot-sdk-show-settings {
  background-color: transparent !important;
}

.loading-layout {
  min-height: 100vh;
  width: 100%;

  text-align: center;

  padding: 20%;
}

.block-separator {
  border: 1px solid #1ba0db;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.main-content {
  min-height: calc(100vh - 400px);
}

.no-padding {
  padding: 0 !important;
}

.main-content-with-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4vw;
  min-height: 100vh;
}

.top-arrow-container {
  max-width: 35px;
  max-height: 35px;
  display: none;
  position: fixed;
  z-index: 2;
  bottom: 2vh;
  right: 2vw;
  width: 9vw;
  height: 9vw;
  cursor: pointer;
  border-radius: 4.5vw;
  box-shadow: 0px 3px 6px #00000029;
  background: radial-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0));
  transition: box-shadow 0.3s;
}

.top-arrow-container:hover {
  background: radial-gradient(rgb(255, 255, 255), rgba(255, 255, 255, 0));
  box-shadow: 0px 3px 6px #00000059;
}

.top-arrow {
  opacity: 0.6;
  width: 100%;

  &:hover {
    opacity: 1;
  }
}

@media (max-width: 720px) {
  .main-content-with-footer {
    padding-top: calc(15vw + 10px);
  }

  .top-arrow-container {
    display: block;
  }
}

@media (max-width: 520px) {
  .main-content-with-footer {
    padding-top: 90px;
  }

  .top-arrow-container {
    border-radius: 6.5vw;
    height: 12vw;
    width: 12vw;
  }

  .top-arrow {
    width: 12vw;
  }
}

@media (min-width: 1920px) {
  .main-content-with-footer {
    padding-top: 4vw;
  }

  .container-lg {
    max-width: 78% !important;
  }
}

@media (min-width: 2560px) {
  .container-lg {
    max-width: 60% !important;
  }
}